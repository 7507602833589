<template>
    <div class="section-content pt-0 pr-0">
        <div v-if="level == 'Property'" class="mt-n2">
            <HbPropertySelector
                v-show="!isOPS"
                id="facility"
                :items="facilityList"
                item-text="name"
                item-value="id"
                v-validate="'required'"
                v-model.lazy="property_id"
                data-vv-scope="gate"
                data-vv-name="facility"
                data-vv-as="Property"
                :error="errors.has('gate.facility')"
            />
        </div>

        <div class="primary-section-content mr-6" :class="[ level !== 'Corporate' && !facilityID ? 'pt-1' : '', accountingToggleOn && showIncomeAccountAlert && level === 'Corporate' ? 'mt-1' : 'mt-n1' ]">

            <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>
            <status @resetStatus="successClear($options.name)" v-if="successHas($options.name)" :message="successGet($options.name)" status="success"></status>

            <hb-notification
                v-model="showIncomeAccountAlert"
                v-if="accountingToggleOn && showIncomeAccountAlert && level === 'Corporate'"
                @close="showIncomeAccountAlert = false"
                type="caution"
                :notDismissable="true"
            >
                There are some fee(s) with unconfigured gl account.
            </hb-notification>

            <template v-if="level === 'Property' && !property_id">
                <hb-empty-state message="Please select a Property to continue." />
            </template>
            
            <template v-else>
            <hb-data-table-header v-if="!isOPS && level !== 'Property' && !facilityID" :class="{ 'mt-n1' : !showIncomeAccountAlert }">
                <template v-slot:description>
                    Add, remove, or edit all fees.
                </template>
                <template v-slot:actions>
                    <hb-btn color="secondary" small @click="addItem()">Add New Fee</hb-btn>
                </template>
            </hb-data-table-header>
            <hb-data-table
                :headers="headers"
                :items="feesList"
                @click:row="editItem"
            >
                <template v-slot:item.name="{ item }">
                    {{item.name}}<br />
                    <span class="hb-text-light">{{item.description}}</span>
                </template>
                <template v-slot:item.gl_account_code="{ item }">
                    <span v-if="item.gl_account_active == 0" class="pr-1">
                    <hb-icon color="#fb4c4c" small>mdi-alert</hb-icon>
                    </span>
                    <span :class="{'hb-text-error' : item.gl_account_active == 0}">{{item.gl_account_code}}</span>
                </template>
                <template v-slot:item.gl_account_name="{ item }">
                    <span v-if="item.gl_account_active == 0" class="pr-1">
                    <hb-icon color="#fb4c4c" small>mdi-alert</hb-icon>
                    </span>
                    <span :class="{'hb-text-error' : item.gl_account_active == 0}">{{item.gl_account_name}}</span>
                </template>
                <template v-slot:item.category_type="{ item }">
                    <span v-if="item.category_type == 'movein'">Move In</span>
                    <span v-else-if="item.category_type == 'moveout'">Move-Out</span>
                    <span class="text-capitalize" v-else>{{ item.category_type }}</span>
                </template>
                <template v-slot:item.recurring="{ item }">{{ item.recurring ? 'Yes' : 'No' }}</template>
                <template v-slot:item.prorate="{ item }">{{ item.prorate ? 'Yes' : 'No' }}</template>
                <template v-slot:item.amount_type="{ item }">
                    <span class="text-capitalize">{{ item.amount_type }}</span>
                </template>
                <template v-slot:item.amount="{ item }">
                    <span v-if="item.amount_type == 'fixed'|| item.amount_type == 'variable'">
                        {{ item.price | formatMoney }}
                    </span>
                    <span v-if="item.amount_type == 'percentage'">
                        {{ item.price | formatPercentage }}
                    </span>
                    <span v-if="item.amount_type == 'scheduled'">
                        <div v-for="(it, index) in item.Rules" :key="index">
                            <template v-if="index == item.Rules.length-1">
                                <span v-if="it.type == 'dollar'">{{it.price | formatMoney}}</span><span v-else class="pr-3">{{it.price}}%</span> <span class="px-2">if rent: <v-icon size="14">mdi-greater-than</v-icon></span> {{(item.Rules[index-1] && item.Rules[index-1].rent_threshold) ? item.Rules[index-1].rent_threshold : 0 | formatMoney}}
                            </template>
                            <template v-else>
                                <span v-if="it.type == 'dollar'">{{it.price | formatMoney}}</span><span v-else class="pr-3">{{it.price}}%</span> <span class="px-2">if rent: <v-icon size="14">mdi-less-than-or-equal</v-icon></span>{{it.rent_threshold | formatMoney}}
                            </template>
                        </div>
                    </span>
                </template>
                <template v-slot:item.actions="{ item }">
                    <hb-menu
                        options
                        align-right
                    >
                        <v-list>
                            <v-list-item @click="editItem(item)">
                                <v-list-item-title>Edit</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="deleteItem(item)">
                                <v-list-item-title>Delete</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </hb-menu>
                </template>
            </hb-data-table>
        </template>
    </div>

        <edit-product v-model="showAdd" v-if="showAdd" :isOPS="isOPS" productType="fees" :productLevel="level" :selected="selected" :facilityID="facilityID || property_id" @showSuccess="showSuccessStatus" @close="closeWindow" @refetch="fetchData" />

        <hb-modal v-model="showDelete" show-help-link v-if="showDelete" size="medium" :title="!usage.length? 'Delete Fee' : 'Cannot Delete Fee'" @close="closeWindow" confirmation>
            <template v-slot:content>
                
                <div class="py-4 px-6" v-if="!usage.length">
                   Are you sure you want to delete {{selected.name}}?
                   <br /><br />
                   This action cannot be undone.
                </div>
                <div class="py-4 px-6" v-else>
                   <strong>{{selected.name}} is currently in use in one or more processes so it cannot be deleted.</strong>
                   <br /><br />
                   <p>To delete this fee, first remove it from the following processes.</p>
                   
                    <ul>
                        <li v-for="(u, i) in usage" :key="i">{{ u.process | process_type }}: {{ u.name }}</li>
                    </ul>
                </div>
                
            </template>
            <template v-slot:actions>
                <hb-btn color="destructive" :disabled="!!usage.length" @click="deleteConfirm">Delete</hb-btn>
                <span v-show="isLoading($options.name)" >
                    <loader color="#00b2ce" size="20px" class="inline-loader"></loader>
                </span>
            </template>
        </hb-modal>

    </div>
</template>

<script type="text/babel">
    import Modal from '../../assets/Modal.vue';
    import EditProduct from '../EditProduct.vue';
    import Status from '../../includes/Messages.vue';
    import settingsTabMixin from '../../../mixins/settingsTabMixin.js';
    import Loader from '../../assets/CircleSpinner.vue';
    import api from '../../../assets/api.js';
    import { mapActions, mapGetters } from 'vuex';
    import { notificationMixin } from "../../../mixins/notificationMixin.js";


    export default {
        name: "FeesIndex",
        mixins:[settingsTabMixin, notificationMixin],
        data() {
            return {
                feesList:[],
                selected: {},
                showAdd: false,
                showEdit: false,
                showDelete: false,
                usage: [],
                destroyComponent : true,
                showIncomeAccountAlert: false,
                property_id: ''
            }
        },
        components:{
            Modal,
            EditProduct,
            Status,
            Loader
        },
        props: {
            level : {
                type: String,
                default: ''
            },
            facilityID: {
                type : String,
                default: ''
            },
            isOPS:{
                type: Boolean,
                default: false
            }
        },
        created(){
            if (this.level === 'Corporate' || this.$props.facilityID) {
                this.fetchData();
            }
            this.getAccountingSettings();
        },
        computed:{
            ...mapGetters({
                facilityList: 'propertiesStore/filtered',
                accountingSettings: "accountingStore/accountingSettings",
                onboardingData: "onboardingTabsStore/getOnboardingData"
            }),
            headers(){
                let tableHeaders = [];
                tableHeaders.push(
                        { text: "Charge Name", value: "name" },
                        { text: "GL Code", value: "gl_account_code" },
                        { text: "GL Name", value: "gl_account_name" },
                        { text: "Category", value: "category_type" },
                        { text: "Recurring", value: "recurring" },
                        { text: "Prorate", value: "prorate" },
                        { text: "Type", value: "amount_type" },
                        { text: "Amount", value: "amount" },
                        { text: "", value: "actions", align: "right", sortable: false, width: 10 }
                    );

                return tableHeaders;
            },
            accountingToggleOn(){
                return Object.keys(this.accountingSettings).length && this.accountingSettings.toggleAccounting == "1"? true : false;
            }
        },
        watch: {
            property_id(p) {
                this.fetchData();
            }
        },
        filters:{
            formatPrice(val){
                if(!val) return '-';
                return "$" + val;
            },
            process_type(process){
                if(process === 'delinquency') return "Delinquency Process";
                if(process === 'template') return "Lease Template"; 
            }
        },
        methods:{
            ...mapActions({
                getAccountingSettings: "accountingStore/getAccountingSettings",
            }),
            initializeData(){
                this.feesList = [];
            },
            closeWindow(){
                this.showEdit = false;
                this.showDelete = false;
                this.showAdd = false;
                this.usage = [];
                this.selected = {};
                setTimeout(() => {
                    this.destroyComponent = false;
                }, 200);
            },
            fetchData(){
                    let path = this.facilityID || this.level === 'Property' ? api.PROPERTIES + (this.facilityID || this.property_id) + '/products?type=late' : (api.PRODUCTS.substring(0, api.PRODUCTS.length - 1) + '?search=late')
                    this.feesList =[];
                    api.get(this, path).then(r => {
                        this.feesList = r.products;
                        this.showIncomeAccountAlert = this.feesList.filter(f => !f.income_account_id).length && this.level === 'Corporate'? true : false;
                    }).catch(err => {
                        if (err === 'Not authorized') {
                            this.feesList = [];
                        }
                    });
            },
            addItem(){
                this.showAdd = true;
            },
            editItem(c){
                this.selected = c;
                this.destroyComponent = true;
                this.showEdit = true;
                this.showAdd = true;
            },
            async deleteItem(c){

                this.selected = c;
                let response = await api.get(this, api.PRODUCTS + `${this.selected.id}/usage`); 
                this.usage = response.usage;
                this.showDelete = true;
            },
            async deleteConfirm(){
                if(this.level === 'Property') {
                  await api.delete(this, api.PROPERTIES, `${this.facilityID || this.property_id}/products/${this.selected.id}`);
                } else {
                  await api.delete(this, api.PRODUCTS, this.selected.id)
                }

                this.showDelete = false;
                this.fetchData();
                this.selected = {};
            },
            showSuccessStatus(name, isUpdated = false){
                  let currentLocalDate = this.$options.filters.formatDateTimeCustom(
                        new Date(),
                        "MMM DD, YYYY @ h:mma."
                    );
                let message = ( isUpdated ? "The '" + name + "' has been updated on " + currentLocalDate : "The '" + name + "' has been created on " + currentLocalDate);
                this.showMessageNotification({ id: this.$options.name, type: "success", description: message });
            }
        },
        mounted(){
             if(this.isOPS && this.level === 'Property' && this.onboardingData.propertyData.length > 0){
                this.property_id = this.onboardingData.propertyData[0].property_id;
            } 
        }
    }
</script>

<style scoped>
    .add-template-btn{
        font-size: 15px;
        text-decoration: none;
    }
    .add-template-btn:focus{
        border: none;
    }
    .ins-templete-table {
        background: #FFFFFF;
        box-shadow: 0px 0px 0px rgba(11, 18, 29, 0.1), 0px 0px 2px rgba(11, 18, 29, 0.1), 0px 1px 2px rgba(11, 18, 29, 0.1);
        border-radius: 4px;
    }
    .ins-templete-table .table-head{
        background: #FFFFFF;
        box-shadow: 0px 1px 0px rgba(11, 18, 29, 0.25);
    }
    .ins-templete-table .table-head strong{
        font-weight: 500;
        font-size: 15px;
        color: #474F5A;
    }
    .ins-templete-table .table-row:hover:not(:first-child){
        /* background: #F9FAFB; */
        cursor: inherit;
    }
    .ins-templete-table .table-row:first-child {
        cursor: inherit;
        border-bottom: 1px solid rgba(0, 0, 0, 0.001);
    }
    .ins-templete-table .table-row .table-cell{
        border-bottom: 1px solid #dce8ef;
    }
    .light-text{
        color: #637381;
    }
    .key-heading{
        font-size: 15px;
        color: #000;
    }
</style>

<style>
    .subdued {
        color: #a5adb4;
        line-height: 18px;
    }
</style>

